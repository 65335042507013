import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Rating,
  Alert,
} from '@mui/material';
import axios from '../utils/axios';
import { useParams } from 'react-router-dom';

const AgentSurvey = () => {
  const { leadId } = useParams();
  const [surveyData, setSurveyData] = useState({
    rating: 0,
    prepared: '',
    answeredQuestions: '',
    differentAgent: '',
    comments: '',
  });
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(null);
  const [message, setMessage] = useState('');
  const [agentName, setAgentName] = useState('');

  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const res = await axios.get(`/api/leads/agentSurvey/${leadId}`);
        if (res.status === 200) {
          setIsValid(true);
          setAgentName(res.data.agentName || 'your agent');
        }
      } catch (err) {
        setIsValid(false);
        setMessage(err.response?.data?.message || 'Error fetching lead data.');
      }
    };

    if (leadId) {
      fetchLeadData();
    }
  }, [leadId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSurveyData({ ...surveyData, [name]: value });
  };

  const handleRatingChange = (event, newValue) => {
    setSurveyData({ ...surveyData, rating: newValue });
  };

  const validateForm = () => {
    const errors = {};
    if (!surveyData.rating) errors.rating = 'Rating is required';
    if (!surveyData.prepared) errors.prepared = 'This field is required';
    if (!surveyData.differentAgent) errors.differentAgent = 'This field is required';
    if (!surveyData.answeredQuestions)
      errors.answeredQuestions = 'This field is required';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    try {
      await axios.post(`/api/leads/agentSurvey/${leadId}`, surveyData);
      setMessage('Survey submitted successfully!');
      setIsValid(false);
    } catch (err) {
      setMessage(
        'Error submitting survey: ' +
          (err.response?.data?.message || err.message)
      );
      setIsValid(false);
    }
  };

  if (isValid === null) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" align="center" style={{ marginTop: '32px' }}>
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={3}
        style={{
          marginTop: '32px',
          padding: '32px',
          backgroundColor: '#f4f6f8',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Agent Survey
        </Typography>
        {isValid ? (
          <>
            <Typography variant="subtitle1" align="center" gutterBottom>
              Please rate your experience with {agentName}
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                component="fieldset"
                style={{ margin: '20px 20px 20px 0' }}
                error={!!errors.rating}
              >
                <FormLabel component="legend">Overall Rating</FormLabel>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Rating
                    name="rating"
                    value={surveyData.rating}
                    onChange={handleRatingChange}
                    size="large"
                  />
                </div>
                {errors.rating && (
                  <Typography color="error">{errors.rating}</Typography>
                )}
              </FormControl>
              <FormControl
                component="fieldset"
                style={{ margin: '20px 0 20px 20px' }}
                error={!!errors.prepared}
              >
                <FormLabel component="legend">Was the agent prepared?</FormLabel>
                <RadioGroup
                  aria-label="prepared"
                  name="prepared"
                  value={surveyData.prepared}
                  onChange={handleChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
                {errors.prepared && (
                  <Typography color="error">{errors.prepared}</Typography>
                )}
              </FormControl>
              <FormControl
                component="fieldset"
                style={{ margin: '16px 0' }}
                error={!!errors.answeredQuestions}
              >
                <FormLabel component="legend">
                  Did they answer all your questions?
                </FormLabel>
                <RadioGroup
                  aria-label="answeredQuestions"
                  name="answeredQuestions"
                  value={surveyData.answeredQuestions}
                  onChange={handleChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
                {errors.answeredQuestions && (
                  <Typography color="error">
                    {errors.answeredQuestions}
                  </Typography>
                )}
              </FormControl>
              <FormControl
                component="fieldset"
                style={{ margin: '20px 0 20px 20px' }}
                error={!!errors.differentAgent}
              >
                <FormLabel component="legend">Would you rather get a different agent?</FormLabel>
                <RadioGroup
                  aria-label="differentAgent"
                  name="differentAgent"
                  value={surveyData.differentAgent}
                  onChange={handleChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
                {errors.differentAgent && (
                  <Typography color="error">{errors.differentAgent}</Typography>
                )}
              </FormControl>
              <TextField
                label="Other Comments"
                name="comments"
                value={surveyData.comments}
                onChange={handleChange}
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                style={{ margin: '16px 0' }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: '32px' }}
              >
                Submit
              </Button>
            </form>
          </>
        ) : (
          <Alert severity={message.includes('submitted') ? 'success' : 'error'}>
            {message}
          </Alert>
        )}
      </Paper>
    </Container>
  );
};

export default AgentSurvey;