import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';
import './Notifications.css';
import { useAuth } from '../AuthContext';
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

const Notifications = () => {
  const { user } = useAuth();
  // const [incomingLeads, setIncomingLeads] = useState([
  //   { type: 'email', value: '' },
  // ]);
  // const [calendarNotifications, setCalendarNotifications] = useState([
  //   { type: 'email', value: '', timeValue: 0, timeUnit: 'minutes' },
  // ]);
  const [adminNotificationId, setAdminNotificationId] = useState(null);
  const [lockoutNotifications, setLockoutNotifications] = useState([
    { name: 'Lockout After', frequency: 0, period: 'Hours' },
    { name: 'Ready to Sell', frequency: 0, period: 'Days' },
  ]);
  // const [agents, setAgents] = useState([]);
  // const [followUpEmail, setFollowUpEmail] = useState({
  //   agentId: '',
  //   subject: '',
  //   body: '',
  // });

  useEffect(() => {
    // fetchNotifications();
    if (user?.role === 'admin') {
      fetchAdminNotifications();
      // fetchAgents();
    }
  }, [user?.role]);

  // const fetchAgents = async () => {
  //   try {
  //     const res = await axios.get('/api/users/agents');
  //     setAgents(res.data);
  //   } catch (err) {
  //     console.error('Error fetching agents:', err);
  //   }
  // };

  // const fetchNotifications = async () => {
  //   try {
  //     const res = await axios.get('/api/notifications');
  //     const { incomingLeads = [], calendarNotifications = [] } =
  //       res.data.notifications;

  //     setIncomingLeads(
  //       incomingLeads.length > 0
  //         ? incomingLeads
  //         : [{ type: 'email', value: '' }]
  //     );
  //     setCalendarNotifications(
  //       calendarNotifications.length > 0
  //         ? calendarNotifications
  //         : [{ type: 'email', value: '', timeValue: 0, timeUnit: 'minutes' }]
  //     );
  //   } catch (err) {
  //     console.error('Error fetching notifications:', err);
  //   }
  // };

  const fetchAdminNotifications = async () => {
    try {
      const res = await axios.get('/api/adminNotifications');
      const { _id, lockoutNotifications = [] } = res.data.adminNotifications;

      setAdminNotificationId(_id);
      setLockoutNotifications(
        lockoutNotifications.length > 0
          ? lockoutNotifications
          : [
              { name: 'Lockout After', frequency: 0, period: 'Hours' },
              { name: 'Ready to Sell', frequency: 0, period: 'Days' },
            ]
      );
    } catch (err) {
      console.error('Error fetching admin notifications:', err);
    }
  };

  const handleSaveAdminNotifications = async () => {
    try {
      const adminNotifications = { lockoutNotifications };
      if (adminNotificationId) {
        await axios.put(
          `/api/adminNotifications/${adminNotificationId}`,
          adminNotifications
        );
      } else {
        await axios.post('/api/adminNotifications', adminNotifications);
      }
      alert('Admin notifications saved successfully.');
      setAdminNotificationId(null);
      fetchAdminNotifications();
    } catch (err) {
      console.error('Error saving admin notifications:', err);
      alert('Failed to save admin notifications.');
    }
  };

  const handleLockoutChange = (index, field, value) => {
    setLockoutNotifications((prev) => {
      const updatedNotifications = [...prev];
      updatedNotifications[index][field] = value;
      return updatedNotifications;
    });
  };

  return (
    <div className='notifications'>
      <h1>Notifications</h1>
      {user?.role === 'admin' && (
        <>
          <div className='notification-section'>
            <h2 style={{ marginBottom: '20px' }}>Lockout Notification</h2>
            <Grid
              container
              spacing={2}
              marginBottom={2}
              alignItems='center'
              key={0}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type='number'
                  label={lockoutNotifications[0].name}
                  value={lockoutNotifications[0].frequency}
                  onChange={(e) =>
                    handleLockoutChange(0, 'frequency', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Period</InputLabel>
                  <Select
                    value={lockoutNotifications[0].period}
                    onChange={(e) =>
                      handleLockoutChange(0, 'period', e.target.value)
                    }
                    label='Period'
                    readOnly
                  >
                    <MenuItem value='Hours'>Hours</MenuItem>
                    <MenuItem value='Days'>Days</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <button onClick={handleSaveAdminNotifications}>
            Save Lockout Notifications
          </button>

          {/* <div className='notification-section'>
            <h2 style={{ marginBottom: '20px' }}>Ready to Sell Notifications</h2>
            <Grid
              container
              spacing={2}
              marginBottom={2}
              alignItems='center'
              key={1}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type='number'
                  label={lockoutNotifications[1].name}
                  value={lockoutNotifications[1].frequency}
                  onChange={(e) =>
                    handleLockoutChange(1, 'frequency', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Period</InputLabel>
                  <Select
                    value={lockoutNotifications[1].period}
                    onChange={(e) =>
                      handleLockoutChange(1, 'period', e.target.value)
                    }
                    label='Period'
                    readOnly
                  >
                    <MenuItem value='Hours'>Hours</MenuItem>
                    <MenuItem value='Days'>Days</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <button onClick={handleSaveAdminNotifications}>
            Save Ready to Sell Notifications
          </button> */}
        </>
      )}
    </div>
  );
};

export default Notifications;