import React, { useEffect, useState } from 'react';
import axios from '../utils/axios';
import { Link } from "react-router-dom";
import { BiSortDown } from "react-icons/bi";
import './Log.css';

const Log = () => {
  const [logs, setLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  // Add pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 100;

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const res = await axios.get('/api/logs');
        setLogs(res.data);
      } catch (err) {
        console.error('Error fetching logs:', err);
      }
    };
    fetchLogs();
  }, []);

  const sortedAndSearchedLogs = logs
    .filter(log => {
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        return (
          (log.leadId?.name?.toLowerCase().includes(searchLower)) ||
          log.message.toLowerCase().includes(searchLower)
        );
      }
      return true;
    })
    .sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });

  // Get current logs
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = sortedAndSearchedLogs.slice(indexOfFirstLog, indexOfLastLog);

  // Calculate total pages
  const totalPages = Math.ceil(sortedAndSearchedLogs.length / logsPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="log">
      <h1>Log</h1>
      
      <div className="log-controls">
        <input
          type="text"
          placeholder="Search logs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="log-search"
        />
        <button
          onClick={() => setSortOrder(prev => prev === 'desc' ? 'asc' : 'desc')}
          className="log-sort"
        >
          <BiSortDown className="sort-icon" />
          {sortOrder === 'desc' ? 'Newest First' : 'Oldest First'}
        </button>
      </div>

      <ul className="log-list">
        {currentLogs.map(log => (
          <li key={log._id} className="log-entry">
            <p>Lead ID: {log.leadId ? (
              <Link to={`/leads/${log.leadId._id}`}>{log.leadId._id}</Link>
            ) : 'N/A'}</p>
            <p>Lead Name: {log.leadId ? log.leadId.name : 'N/A'}</p>
            {log.message} - {new Date(log.createdAt).toLocaleString()}
          </li>
        ))}
      </ul>

<div className="pagination">
  <button 
    onClick={() => paginate(currentPage - 1)}
    disabled={currentPage === 1}
    className="log-sort"
  >
    Previous Page
  </button>
  <span className="page-info">
    Page {currentPage} of {totalPages}
  </span>
  <button 
    onClick={() => paginate(currentPage + 1)}
    disabled={currentPage === totalPages}
    className="log-sort"
  >
    Next Page
  </button>
</div>
    </div>
  );
};

export default Log;