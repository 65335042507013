import React from 'react';
import { Alert, AlertTitle, Box, Collapse, IconButton, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import WarningIcon from '@mui/icons-material/Warning';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useState } from 'react';

const AgentStatusMessage = ({ agent }) => {
  const [showHistory, setShowHistory] = useState(false);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusMessage = () => {
    if (agent?.isLocked && agent?.lockInfo) {
      return {
        severity: 'error',
        title: 'Account Locked',
        message: (
          <Box>
            <Typography sx={{ mb: 1 }}>
              <strong>Reason:</strong> {agent.lockInfo.details}
            </Typography>
            <Typography sx={{ mb: 1 }}>
              <strong>Instructions to unlock:</strong> {agent.lockInfo.unlockInstructions}
            </Typography>
            <Typography sx={{ color: 'text.secondary', fontSize: '0.9em' }}>
              Locked since: {formatDate(agent.lockInfo.timestamp)}
            </Typography>
            {agent.lockHistory && agent.lockHistory.length > 1 && (
              <Box sx={{ mt: 1 }}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    cursor: 'pointer',
                    color: 'primary.main',
                    '&:hover': { textDecoration: 'underline' }
                  }}
                  onClick={() => setShowHistory(!showHistory)}
                >
                  <Typography sx={{ fontSize: '0.9em', mr: 0.5 }}>
                    View Lock History
                  </Typography>
                  <IconButton size="small">
                    {showHistory ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Box>
                <Collapse in={showHistory}>
                  <Box sx={{ mt: 1, pl: 2, borderLeft: '2px solid rgba(0, 0, 0, 0.1)' }}>
                    {agent.lockHistory
                      .filter(lock => lock.timestamp !== agent.lockInfo.timestamp)
                      .map((lock, index) => (
                        <Box key={index} sx={{ mb: 2, fontSize: '0.9em' }}>
                          <Typography sx={{ color: 'text.secondary' }}>
                            {formatDate(lock.timestamp)}
                          </Typography>
                          <Typography>
                            <strong>Reason:</strong> {lock.details}
                          </Typography>
                          <Typography sx={{ fontSize: '0.9em', color: 'text.secondary' }}>
                            {lock.unlockInstructions}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                </Collapse>
              </Box>
            )}
          </Box>
        ),
        icon: <LockIcon sx={{ marginRight: 1 }} />
      };
    }

    if (agent?.isRestricted) {
      return {
        severity: 'warning',
        title: 'Account Restricted',
        message: 'Your account is restricted. Please complete the contact form to remove this restriction.',
        icon: <WarningIcon sx={{ marginRight: 1 }} />
      };
    }

    if (agent?.isOnHoliday) {
      return {
        severity: 'info',
        title: 'On Holiday',
        message: 'You are currently marked as being on holiday. New leads will not be assigned during this time.',
        icon: <EventIcon sx={{ marginRight: 1 }} />
      };
    }

    return null;
  };

  const status = getStatusMessage();
  
  if (!status) {
    return null;
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Alert 
        severity={status.severity}
        icon={status.icon}
        sx={{ 
          width: '100%',
          alignItems: 'flex-start',
          '& .MuiAlert-message': {
            width: '100%'
          }
        }}
      >
        <AlertTitle sx={{ mb: 2 }}>{status.title}</AlertTitle>
        {status.message}
      </Alert>
    </Box>
  );
};

export default AgentStatusMessage;