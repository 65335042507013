import React, { useEffect, useState } from 'react';

import { 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody, 
    Typography,
    Rating,
    Box,
    Divider,
    Pagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    Paper,
    useMediaQuery,
    useTheme,
    Card,
    CardContent,
    Stack
} from '@mui/material';
import axios from '../utils/axios';
import { useAuth } from '../AuthContext';


const AgentSurveys = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useAuth();
    const [leadSurveys, setLeadSurveys] = useState([]);
    const [agentSurveys, setAgentSurveys] = useState([]);
    const [agents, setAgents] = useState([]);
    const [leadSurveyPage, setLeadSurveyPage] = useState(1);
    const [agentSurveyPage, setAgentSurveyPage] = useState(1);
    const [selectedAgentForLeads, setSelectedAgentForLeads] = useState('all');
    const [selectedAgentForAgents, setSelectedAgentForAgents] = useState('all');
    const surveysPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [leadRes, agentRes, agentsRes] = await Promise.all([
                    axios.get('/api/leads/leadSurveys'),
                    axios.get('/api/leads/agentSurveys'),
                    axios.get('/api/users/agents')
                ]);
                
                if (user.role === 'admin') {
                    setLeadSurveys(leadRes.data);
                    setAgentSurveys(agentRes.data);
                    setAgents(agentsRes.data);
                } else {
                    // Check if we have the user ID
                    if (!user.id && !user._id) {
                        console.error('User ID is missing!');
                        return;
                    }
                    
                    const userId = user.id || user._id; // Try both possible ID fields
                    
                    const filteredLeadSurveys = leadRes.data.filter(survey => 
                        (survey.agentId?._id === userId) || (survey.agentId === userId)
                    );
                    
                    const filteredAgentSurveys = agentRes.data.filter(survey => 
                        (survey.agentId?._id === userId) || (survey.agentId === userId)
                    );
                    
                    setLeadSurveys(filteredLeadSurveys);
                    setAgentSurveys(filteredAgentSurveys);
                }
            } catch (err) {
                console.error('Error fetching surveys:', err);
            }
        };
    
        // Only fetch if we have user data
        if (user) {
            fetchData();
        }
    }, [user]);

    // Filter surveys based on selected agent (only for admin)
    const filteredLeadSurveys = user.role === 'admin' 
        ? leadSurveys.filter(survey => selectedAgentForLeads === 'all' || survey.agentId?._id === selectedAgentForLeads)
        : leadSurveys;

    const filteredAgentSurveys = user.role === 'admin'
        ? agentSurveys.filter(survey => selectedAgentForAgents === 'all' || survey.agentId?._id === selectedAgentForAgents)
        : agentSurveys;

    // Get current surveys for each page
    const leadSurveysLastIndex = leadSurveyPage * surveysPerPage;
    const leadSurveysFirstIndex = leadSurveysLastIndex - surveysPerPage;
    const currentLeadSurveys = filteredLeadSurveys.slice(leadSurveysFirstIndex, leadSurveysLastIndex);

    const agentSurveysLastIndex = agentSurveyPage * surveysPerPage;
    const agentSurveysFirstIndex = agentSurveysLastIndex - surveysPerPage;
    const currentAgentSurveys = filteredAgentSurveys.slice(agentSurveysFirstIndex, agentSurveysLastIndex);

    // Calculate total pages
    const leadSurveyTotalPages = Math.ceil(filteredLeadSurveys.length / surveysPerPage);
    const agentSurveyTotalPages = Math.ceil(filteredAgentSurveys.length / surveysPerPage);

    const LeadSurveyContent = () => {
        const AgentNameWithRating = ({ agent }) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {agent?.username || 'N/A'}
                {agent?.rating && (
                    <>
                        <Typography component="span" sx={{ color: 'text.secondary' }}>
                            ({agent.rating.toFixed(1)}
                        </Typography>
                        <Rating 
                            value={1} 
                            max={1} 
                            readOnly 
                            size="small"
                            sx={{ color: 'primary.main' }}
                        />
                        <Typography component="span" sx={{ color: 'text.secondary' }}>
                            )
                        </Typography>
                    </>
                )}
            </Box>
        );
    
        if (isMobile) {
            return (
                <Stack spacing={2}>
                    {currentLeadSurveys.map((survey) => (
                        <Card key={survey._id} variant="outlined">
                            <CardContent>
                                <Typography variant="subtitle2" gutterBottom>
                                    Agent: <AgentNameWithRating agent={survey.agentId} />
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>Lead: {survey.leadId?.name || 'N/A'}</Typography>
                                <Box my={1}>
                                    <Typography variant="subtitle2" component="span">Rating: </Typography>
                                    <Rating value={survey.rating} readOnly size="small" />
                                </Box>
                                <Typography variant="body2">Prepared: {survey.prepared}</Typography>
                                <Typography variant="body2">Answered Questions: {survey.answeredQuestions}</Typography>
                                <Typography variant="body2">Different Agent: {survey.differentAgent}</Typography>
                                <Typography variant="body2" style={{ wordBreak: 'break-word' }}>Comments: {survey.comments || '-'}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {new Date(survey.createdAt).toLocaleString()}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>
            );
        }
    
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Agent</TableCell>
                            <TableCell>Lead Name</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>Prepared</TableCell>
                            <TableCell>Answered Questions</TableCell>
                            <TableCell>Different Agent</TableCell>
                            <TableCell>Comments</TableCell>
                            <TableCell>Submitted At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentLeadSurveys.map((survey) => (
                            <TableRow key={survey._id}>
                                <TableCell>
                                    <AgentNameWithRating agent={survey.agentId} />
                                </TableCell>
                                <TableCell>{survey.leadId?.name || 'N/A'}</TableCell>
                                <TableCell>
                                    <Rating value={survey.rating} readOnly />
                                </TableCell>
                                <TableCell>{survey.prepared}</TableCell>
                                <TableCell>{survey.answeredQuestions}</TableCell>
                                <TableCell>{survey.differentAgent}</TableCell>
                                <TableCell>{survey.comments || '-'}</TableCell>
                                <TableCell>{new Date(survey.createdAt).toLocaleString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const AgentSurveyContent = () => {
        if (isMobile) {
            return (
                <Stack spacing={2}>
                    {currentAgentSurveys.map((survey) => (
                        <Card key={survey._id} variant="outlined">
                            <CardContent>
                                <Typography variant="subtitle2" gutterBottom>Agent: {survey.agentId?.username || 'N/A'}</Typography>
                                <Typography variant="subtitle2" gutterBottom>Lead: {survey.leadId?.name || 'N/A'}</Typography>
                                <Typography variant="body2">Status: {survey.status}</Typography>
                                <Typography variant="body2" style={{ wordBreak: 'break-word' }}>Comments: {survey.comments}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {new Date(survey.createdAt).toLocaleString()}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>
            );
        }

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Agent</TableCell>
                            <TableCell>Lead Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Comments</TableCell>
                            <TableCell>Submitted At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentAgentSurveys.map((survey) => (
                            <TableRow key={survey._id}>
                                <TableCell>{survey.agentId?.username || 'N/A'}</TableCell>
                                <TableCell>{survey.leadId?.name || 'N/A'}</TableCell>
                                <TableCell>{survey.status}</TableCell>
                                <TableCell>{survey.comments}</TableCell>
                                <TableCell>{new Date(survey.createdAt).toLocaleString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
            {/* Lead Surveys Section */}
            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    {user.role === 'admin' 
                        ? 'Surveys Filled Out By Leads'
                        : 'Lead Surveys About You'
                    }
                </Typography>
                {user.role === 'admin' && (
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel>Filter by Agent</InputLabel>
                            <Select
                                value={selectedAgentForLeads}
                                onChange={(e) => {
                                    setSelectedAgentForLeads(e.target.value);
                                    setLeadSurveyPage(1);
                                }}
                                label="Filter by Agent"
                            >
                                <MenuItem value="all">All Agents</MenuItem>
                                {agents.map((agent) => (
                                    <MenuItem key={agent._id} value={agent._id}>
                                        {agent.username}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                
                <LeadSurveyContent />

                {leadSurveyTotalPages > 1 && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Pagination 
                            count={leadSurveyTotalPages}
                            page={leadSurveyPage}
                            onChange={(e, page) => setLeadSurveyPage(page)}
                            color="primary"
                            size={isMobile ? "small" : "medium"}
                        />
                    </Box>
                )}
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* Agent Surveys Section */}
            <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                    {user.role === 'admin' 
                        ? 'Surveys Filled Out By Agents'
                        : 'Surveys You Submitted'
                    }
                </Typography>
                {user.role === 'admin' && (
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel>Filter by Agent</InputLabel>
                            <Select
                                value={selectedAgentForAgents}
                                onChange={(e) => {
                                    setSelectedAgentForAgents(e.target.value);
                                    setAgentSurveyPage(1);
                                }}
                                label="Filter by Agent"
                            >
                                <MenuItem value="all">All Agents</MenuItem>
                                {agents.map((agent) => (
                                    <MenuItem key={agent._id} value={agent._id}>
                                        {agent.username}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                <AgentSurveyContent />

                {agentSurveyTotalPages > 1 && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Pagination 
                            count={agentSurveyTotalPages}
                            page={agentSurveyPage}
                            onChange={(e, page) => setAgentSurveyPage(page)}
                            color="primary"
                            size={isMobile ? "small" : "medium"}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default AgentSurveys;