import React, {useEffect, useState} from 'react';
import axios from '../utils/axios';
import {useMediaQuery} from '@mui/material';

import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Toolbar,
    Pagination,
    Link as MuiLink,
} from '@mui/material';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import AlertBar from './AlertBar';
import AgentStatusMessage from './AgentStatusMessage';

import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Note as NoteIcon,
    Event as EventIcon,
    Sort as SortIcon,
    Email as EmailIcon,
    Phone as PhoneIcon,
} from '@mui/icons-material';
import moment from 'moment';
import {useAuth} from '../AuthContext';
import {Bar} from 'react-chartjs-2';
import {Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend} from 'chart.js';
import {FcCheckmark} from "react-icons/fc";
import LeadsList from './LeadsList';
import LeadDetails from './LeadDetails';
import {useParams} from "react-router-dom";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Leads = () => {
    const {leadId} = useParams();
    const navigate = useNavigate();
    const [leads, setLeads] = useState([]);
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState('');
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [newLead, setNewLead] = useState({
        name: '',
        email: '',
        phone: '',
        municipality: '',
        classification: '',
    });
    const [editLead, setEditLead] = useState({
        name: '',
        email: '',
        phone: '',
        municipality: '',
        classification: '',
        assignedAgent: '',
    });
    const [formData, setFormData] = useState({
        comments: '',
        status: '',
    });
    const [selectedLead, setSelectedLead] = useState(null);
    const [assignedStatus, setAssignedStatus] = useState(null);
    const [meetingDate, setMeetingDate] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [logs, setLogs] = useState([]);
    const [events, setEvents] = useState([]);
    const [newNote, setNewNote] = useState('');
    const [notes, setNotes] = useState([]);
    const [leadsStatuses, setLeadsStatuses] = useState([]);
    const [showMeetingDateField, setShowMeetingDateField] = useState(false);
    const [showMetWithLeadPopup, setShowMetWithLeadPopup] = useState(false);
    const isMobile = useMediaQuery('(max-width:1055px)');

    const [eventOpen, setEventOpen] = useState(false);
    const [alerts, setAlerts] = useState([]);
    
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Leads Per Day',
                data: [],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    });
    const [newEvent, setNewEvent] = useState({
        eventName: '',
        eventDate: '',
        notify: false,
    });
    const [reviewOpen, setReviewOpen] = useState(false);
    const [reviewNote, setReviewNote] = useState('');
    const {user, logout} = useAuth();
    const [message, setMessage] = useState('');
    const [leadStatusesMapById, setLeadStatusesMapById] = useState({});
    const [isRestricted, setIsRestricted] = useState(false);
    const [sortConfig, setSortConfig] = useState({
        key: 'createdAt',
        direction: 'descending',
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const leadsPerPage = 25;

    const handleAssignedStatusChange = async (e) => {
        const assignedStatusId = e.target.value;
        console.log('assigned status', assignedStatusId);
        // if(assignedStatusId) {
        //   const assignedStatus = leadStatusesMapById[assignedStatusId];
        //   if(assignedStatus.definedAction in definedActions) {
        //     await reset();
        //     const selectedAction = definedActions[assignedStatus.definedAction];
        //     await selectedAction();
        //   }
        // }
        setAssignedStatus(e.target.value);
    };

    const handleDateChange = async (e) => {
        setMeetingDate(e.target.value);
    };

    const definedActions = {
        null: async () => {
            setShowMeetingDateField(false);
            setShowMetWithLeadPopup(false);
        },
        'showMeetingDate': async () => {
            setShowMetWithLeadPopup(false);
            setShowMeetingDateField(true);
        },
        'showMetWithLeadPopup': async () => {
            setShowMeetingDateField(false);
            if (!!!selectedLead.agentSurvey) {
                // show popup only when the agent survey does not exists.
                setShowMetWithLeadPopup(true);
            }
        }
    };
    const fetchLeads = async () => {
        try {
            const res = await axios.get('/api/leads');
            setLeads(res.data);
        } catch (err) {
            console.error(err);
            alert('Failed to fetch leads.');
        }
    };

    const fetchAgents = async () => {
        try {
            const res = await axios.get('/api/users/agents');
            setAgents(res.data);
        } catch (err) {
            console.error(err);
            alert('Failed to fetch agents.');
        }
    };

    const fetchMyUser = async () => {
        try {
            const res = await axios.get('/api/users/me');
            console.log('User data:', res.data);
            setIsRestricted(res.data.isRestricted);
            
            // Update all lock-related information
            user.isLocked = res.data.isLocked;
            user.lockInfo = res.data.lockInfo;
            user.lockHistory = res.data.lockHistory;
            
            console.log('Updated user lock info:', user.lockInfo); // Debug log
        } catch (err) {
            console.error(err);
            alert('Failed to fetch user.');
        }
    };

    const fetchMyAlerts = async () => {
        try {
            const res = await axios.get('/api/alerts');
            setAlerts(res.data);
        } catch (err) {
            console.error(err);
        }
    }

    const fetchStatus = async () => {
        try {
            const {data} = await axios.get('/api/status');
            setLeadsStatuses(data);
            const map = data.reduce((acc, o) => {
                acc[o._id] = o;
                return acc;
            }, {});
            setLeadStatusesMapById(map);
        } catch (err) {
            console.error(err);
        }
    }

    const calculateChartData = (filteredLeads) => {
        let leadsPerDay = filteredLeads.sort((a, b) => {
            return moment(a.createdAt) > moment(b.createdAt)
        }).reduce((acc, lead) => {
            const day = moment(lead.createdAt).format('YYYY-MM-DD');
            acc[day] = (acc[day] || 0) + 1;
            return acc;
        }, {});

        const chartData = {
            labels: Object.keys(leadsPerDay),
            datasets: [
                {
                    label: 'Leads Per Day',
                    data: Object.values(leadsPerDay),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
            ],
        };
        console.log('setting new chart data...');
        setChartData(chartData);
        console.log('new chart data set...');
    };

    useEffect(() => {
        console.log('User locked status:', user.isLocked);
      }, [user.isLocked]);

    useEffect(() => {
        if (user.role === 'admin') {
            fetchAgents();
        } else if (user.role === 'agent') {
            fetchMyUser();
        }
        fetchLeads();
        fetchStatus();

        // Cleanup function
        return () => {
            setAgentFilter('');
        };
    }, [user.role]);

    useEffect(() => {
        calculateChartData(leads);
    }, [leads]);

    const [isLoadingNotes, setIsLoadingNotes] = useState(false);

    useEffect(() => {
        if (selectedLead) {
            const fetchData = async () => {
                setIsLoadingNotes(true);
                try {
                    setNotes([]);
                    const res = await axios.get(`/api/leads/${selectedLead._id}/notes`);
                    if (res.data) {
                        setNotes(res.data);
                    }
                } catch (err) {
                    console.error('Error fetching notes:', err);
                    setNotes([]);
                } finally {
                    setIsLoadingNotes(false);
                }
            };

            fetchData();
        }
    }, [selectedLead]);

    useEffect(() => {
        if (selectedLead) {
            const fetchLogs = async () => {
                try {
                    setLogs([]);
                    const res = await axios.get(`/api/leads/${selectedLead._id}/logs`);
                    setLogs(res.data);
                } catch (err) {
                    console.error(err);
                    alert('Failed to fetch logs.');
                }
            };

            const fetchEvents = async () => {
                try {
                    setEvents([]);
                    const res = await axios.get(`/api/leads/${selectedLead._id}/events`);
                    setEvents(res.data);
                } catch (err) {
                    console.error('Error fetching events:', err);
                }
            };

            const fetchNotes = async () => {
                try {
                    setNotes([]);
                    const res = await axios.get(`/api/leads/${selectedLead._id}/notes`);
                    setNotes(res.data);
                } catch (err) {
                    console.error(err);
                    alert('Failed to fetch notes.');
                }
            };

            if (user.role === 'admin') {
                fetchLogs();
                fetchEvents();
            }
            fetchNotes();
        }
        return () => {
        };
    }, [selectedLead, user.role]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseMetWithLead = () => setShowMetWithLeadPopup(false);
    const handleEditOpen = () => setEditOpen(true);
    const handleEditClose = () => setEditOpen(false);
    const handleDeleteOpen = () => setDeleteOpen(true);
    const handleDeleteClose = () => setDeleteOpen(false);
    // const handleEventOpen = () => setEventOpen(true);
    const handleEventClose = () => setEventOpen(false);
    const handleReviewOpen = () => setReviewOpen(true);
    const handleReviewClose = () => setReviewOpen(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setNewLead({...newLead, [name]: value});
    };

    const handleMetWithLeadInputChange = async (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    const handleEventChange = (e) => {
        const {name, value, type, checked} = e.target;
        setNewEvent({...newEvent, [name]: type === 'checkbox' ? checked : value});
    };

    const handleAddLead = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post('/api/leads', newLead);

            setNewLead({
                name: '',
                email: '',
                phone: '',
                municipality: '',
                classification: '',
            });

            const addedLead = res.data;

            setLeads([...leads, addedLead]);
            handleClose();
        } catch (err) {
            console.error(err);
            alert('Failed to add lead.');
        }
    };

    const handleEditLead = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.put(`/api/leads/${selectedLead._id}`, editLead);
            const updatedLeads = leads.map((lead) =>
                lead._id === res.data._id ? res.data : lead
            );
            setLeads(updatedLeads);
            handleEditClose();
        } catch (err) {
            console.error(err);
            alert('Failed to edit lead.');
        }
    };

    const handleDeleteLead = async () => {
        try {
            await axios.delete(`/api/leads/${selectedLead._id}`);
            const updatedLeads = leads.filter(
                (lead) => lead._id !== selectedLead._id
            );
            setLeads(updatedLeads);
            handleDeleteClose();
            setSelectedLead(null);
            setSidebarOpen(false);
        } catch (err) {
            console.error('Error deleting lead:', err);
            alert('Failed to delete lead.');
        }
    };

    const handleLeadClick = async (lead) => {
        // Add check for agent role and lead status
        const isAcceptedByAgent = (lead.assignedAgent && user.id === lead.assignedAgent._id && lead.status !== "Pending");
        const isAdmin = user.role === 'admin';
        if (isAcceptedByAgent || isAdmin) {
            // Existing functionality continues if they're not an agent or if they have accepted the lead
            setSelectedLead(lead);
            setEditLead({
                name: lead.name,
                email: lead.email,
                phone: lead.phone,
                municipality: lead.municipality,
                classification: lead.classification,
                assignedAgent: lead.assignedAgent ? lead.assignedAgent._id : '',
            });
            try {
                await fetchAgents();
            } catch (err) {
                console.error(err);
            }
            setSelectedAgent(lead.assignedAgent ? lead.assignedAgent._id : '');
            setSidebarOpen(true);
            setAssignedStatus(lead.assignedStatus?._id);
        } else {
            // Either show nothing, or you could alert them that they need to accept the lead first
            // alert('Please accept the lead to view details');
            return;
        }
    };

    const handleSidebarClose = () => {
        setSidebarOpen(false);
        setSelectedLead(null);
        setAssignedStatus(null);
        setMeetingDate(null);
        setShowMeetingDateField(false);
        setShowMetWithLeadPopup(false);
        setNotes([]); // Add this line to clear notes
    };

    const handleAssignAgent = async () => {
        try {
            await axios.put(`/api/leads/${selectedLead._id}/assign`, {
                agentId: selectedAgent,
            });
            setLeads(
                leads.map((lead) =>
                    lead._id === selectedLead._id
                        ? {
                            ...lead,
                            assignedAgent: agents.find(
                                (agent) => agent._id === selectedAgent
                            ),
                            status: 'Pending',
                        }
                        : lead
                )
            );
            handleSidebarClose();
            setMessage('Agent assigned successfully');
        } catch (err) {
            console.error(err);
            setMessage('Failed to assign agent');
        }
    };

    const handleAssignStatus = async () => {
        try {
            const res = await axios.put(`/api/leads/${selectedLead._id}/assign-status`, {
                status: assignedStatus,
                additionalParams: {
                    ...formData,
                    meetingDate: meetingDate,
                }
            });
            if (res?.data?.error) {
                alert(res.data.error);
                return;
            }
            const statusId = assignedStatus;
            const leadStatusIdx = leadsStatuses.findIndex(e => e._id === statusId);
            selectedLead.assignedStatus = leadsStatuses[leadStatusIdx];
            
            // Fetch updated user data and update all lock-related information
            const userRes = await axios.get('/api/users/me');
            user.isLocked = userRes.data.isLocked;
            user.lockInfo = userRes.data.lockInfo;
            user.lockHistory = userRes.data.lockHistory;
            
            // If this was the lead that caused the lock, and the status is now set, we can close the sidebar
            if (user.lockInfo?.leadId === selectedLead._id) {
                await handleSidebarClose();
            }
    
            // Refresh the user's data to update the UI
            await fetchMyUser();
            
        } catch (err) {
            console.error(err);
            setMessage('Failed to set status');
        }
        setAssignedStatus(null);
    };

    const handleAddNote = async () => {
        try {
            const res = await axios.post(`/api/leads/${selectedLead._id}/notes`, {
                note: newNote,
                userId: user.id,  // Add this line
            });
            const log = {
                _id: res.data._id,
                message: res.data.message,
                createdAt: new Date(res.data.createdAt),
                type: 'note',
                author: {
                    username: user.username,
                    role: user.role
                }
            };
            setLogs([...logs, log]);
            setNewNote('');
            setNotes([...notes, log]);
        } catch (err) {
            console.error(err);
            alert('Failed to add note');
        }
    };


    const handleAddReviewNote = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.post(
                `/api/leads/${selectedLead._id}/reviewNote`,
                {
                    reviewNote: reviewNote,
                }
            );
            const log = {
                _id: res.data._id,
                message: res.data.message,
                createdAt: new Date(res.data.createdAt),
                type: 'review note',
            };
            setLogs([...logs, log]);
            setNotes([...notes, log]);
            setReviewNote('');
            setReviewOpen(false);
            window.location.reload();
        } catch (err) {
            console.error(err);
            alert('Failed to add note');
        }
    };

    const handleAddEvent = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(
                `/api/leads/${selectedLead._id}/events`,
                newEvent
            );
            const eventLogMessage = `Event "${res.data.eventName}" added for lead "${
                selectedLead.name
            }" on ${new Date(res.data.eventDate).toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            })} with notification ${res.data.notify ? 'enabled' : 'disabled'}`;
            const log = {
                _id: res.data._id,
                message: eventLogMessage,
                createdAt: new Date(),
                type: 'event',
            };
            setLogs([...logs, log]);
            setEvents([...events, res.data]);
            setNewEvent({eventName: '', eventDate: '', notify: false});
            handleEventClose();
        } catch (err) {
            console.error(err);
            if (err.response && err.response.status === 401) {
                alert('Session expired, please log in again.');
                logout();
            } else {
                alert('Failed to add event');
            }
        }
    };

    const handleMetWithLeadForm = async (e) => {
        e.preventDefault();
        if (formData.comments && formData.status) {
            await handleAssignStatus();
            setShowMetWithLeadPopup(false);
        } else {
            alert('Please, fill in the form before submitting!');
        }
    };

    const handleStatusChange = async (lead, status) => {
        try {
            let updatedLeads;
            const res = await axios.put(`/api/leads/${lead._id}/status`, {status});
            updatedLeads = leads.map((l) =>
                l._id === res.data._id ? res.data : l
            );
            setLeads(updatedLeads);
            await fetchLeads();
        } catch (err) {
            console.error(err);
            alert('Failed to update status.');
        }
    };

    


    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const [agentFilter, setAgentFilter] = useState('');

    const sortedLeads = React.useMemo(() => {
        let sortableLeads = [...leads];
        if (sortConfig.key !== '') {
            sortableLeads.sort((a, b) => {
                const aValue = a[sortConfig.key] || '';
                const bValue = b[sortConfig.key] || '';
                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableLeads;
    }, [leads, sortConfig]);

    const filteredLeads = sortedLeads.filter((lead) => {
        const matchesSearch = Object.keys(lead).some((key) =>
            lead[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );

        const matchesAgent = !agentFilter ||
            (lead.assignedAgent && lead.assignedAgent._id === agentFilter);

        return matchesSearch && matchesAgent;
    });

    

    // Pagination logic
    const indexOfLastLead = currentPage * leadsPerPage;
    const indexOfFirstLead = indexOfLastLead - leadsPerPage;
    const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);
    const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    // Data processing for the chart


    return (
        
        <Box padding={3}>
    {alerts.map((item, index) => (
        <AlertBar key={index} item={item}/>
    ))}

    {user?.isLocked && (
        <Box 
            bgcolor="error.main" 
            color="error.contrastText" 
            padding={2} 
            marginBottom={2} 
            textAlign="left"
            borderRadius={1}
            display="flex"
            flexDirection="column"
            gap={1}
        >
            <Typography variant="h6" component="div">
                Account Locked - You cannot receive new leads from an admin
            </Typography>
            <Typography>
                <strong>Reason:</strong> {user?.lockInfo?.details || "Not specified"}
            </Typography>
            <Typography>
                <strong>Instructions:</strong> {user?.lockInfo?.unlockInstructions || "Not specified"}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.9 }}>
                Locked since: {user?.lockInfo?.timestamp ? new Date(user.lockInfo.timestamp).toLocaleString() : "Unknown"}
            </Typography>
            {/* Debug information */}
            
        </Box>
    )}


    {isRestricted && (
        <Box bgcolor="red" color="white" padding={2} marginBottom={2} textAlign="center">
            You are restricted. Please fill out the contact form to remove this restriction.
        </Box>
    )}
            
            {/* Chart */}
            {user?.role === 'admin' && (
                <Box marginBottom={4}>
                    <Bar data={chartData} options={{maintainAspectRatio: false}} height={400}/>
                </Box>
            )}
            <Box margin={4}>
                <LeadDetails leadId={leadId} leadStatuses={leadsStatuses}/>
            </Box>
            <Toolbar className="leads-toolbar" sx={{justifyContent: 'space-between', marginBottom: 3, flexWrap: 'wrap', gap: 2, paddingLeft: 0, paddingRight: 0, padding: 0}}>
            <Box sx={{display: 'flex', gap: 2, flexGrow: 1, maxWidth: '600px', padding: 0 }}>
    {user.role === 'admin' && (
        <FormControl sx={{minWidth: 400}}>
            <InputLabel>Filter by Agent</InputLabel>
            <Select
                value={agentFilter}
                onChange={(e) => setAgentFilter(e.target.value)}
                label="Filter by Agent"
            >
                <MenuItem value="">All Agents</MenuItem>
                {agents.map((agent) => (
                    <MenuItem key={agent._id} value={agent._id}>
                        {agent.username}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )}
</Box>
                {(user.role === 'admin' || user.role === 'agent') && (
                    <Button variant="contained" onClick={handleOpen} startIcon={<AddIcon/>}>
                        Add Lead
                    </Button>
                )}
            </Toolbar>

            {isMobile ? (
                <LeadsList
                    leads={currentLeads}
                    user={user}
                    handleLeadClick={handleLeadClick}
                    handleStatusChange={handleStatusChange}
                />
            ) : (

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => handleSort('name')}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        Name
                                        {sortConfig.key === 'name' && (
                                            <SortIcon
                                                sx={{transform: sortConfig.direction === 'ascending' ? 'rotate(0deg)' : 'rotate(180deg)'}}/>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell onClick={() => handleSort('municipality')}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        Municipality
                                        {sortConfig.key === 'municipality' && (
                                            <SortIcon
                                                sx={{transform: sortConfig.direction === 'ascending' ? 'rotate(0deg)' : 'rotate(180deg)'}}/>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell onClick={() => handleSort('classification')}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        Classification
                                        {sortConfig.key === 'classification' && (
                                            <SortIcon
                                                sx={{transform: sortConfig.direction === 'ascending' ? 'rotate(0deg)' : 'rotate(180deg)'}}/>
                                        )}
                                    </Box>
                                </TableCell>
                                {user?.role === 'admin' && (
                                    <TableCell onClick={() => handleSort('assignedAgent.username')}>
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            Assigned To
                                            {sortConfig.key === 'assignedAgent.username' && (
                                                <SortIcon
                                                    sx={{transform: sortConfig.direction === 'ascending' ? 'rotate(0deg)' : 'rotate(180deg)'}}/>
                                            )}
                                        </Box>
                                    </TableCell>)}
                                <TableCell onClick={() => handleSort('createdAt')}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        Created At
                                        {sortConfig.key === 'createdAt' && (
                                            <SortIcon
                                                sx={{transform: sortConfig.direction === 'ascending' ? 'rotate(0deg)' : 'rotate(180deg)'}}/>
                                        )}
                                    </Box>
                                </TableCell>
                                {user.role === 'agent' && <TableCell>Actions</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentLeads.map((lead, index) => (
                                <TableRow key={lead._id}
                                          sx={{backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff'}}>
                                    <TableCell onClick={() => handleLeadClick(lead)}>
                                        <Box display="flex" alignItems="center">
                                            {lead.name}
                                            <Box display="flex" ml={2}>
                                                <Box
                                                    sx={{
                                                        position: 'relative',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: 24,
                                                        height: 24,
                                                        bgcolor: '#e0e0e0',
                                                        borderRadius: '50%',
                                                        mr: 1,
                                                        cursor: 'pointer',
                                                        '&:hover .info': {
                                                            width: 'auto',
                                                            opacity: 1,
                                                            zIndex: 10,
                                                        },
                                                    }}
                                                >
                                                    <PhoneIcon sx={{fontSize: 14, color: 'grey'}}/>
                                                    <Box
                                                        className="info"
                                                        sx={{
                                                            position: 'absolute',
                                                            bottom: '120%',
                                                            left: '50%',
                                                            transform: 'translateX(-50%)',
                                                            zIndex: 10,
                                                            transition: 'all 0.3s ease',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            width: 0,
                                                            opacity: 0,
                                                            bgcolor: 'background.paper',
                                                            px: 1,
                                                            py: 0.5,
                                                            borderRadius: 1,
                                                            boxShadow: 1,
                                                        }}
                                                    >
                                                        {lead.phone}
                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        position: 'relative',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: 24,
                                                        height: 24,
                                                        bgcolor: '#e0e0e0',
                                                        borderRadius: '50%',
                                                        cursor: 'pointer',
                                                        '&:hover .info': {
                                                            width: 'auto',
                                                            opacity: 1,
                                                            zIndex: 10,
                                                        },
                                                    }}
                                                >
                                                    <EmailIcon sx={{fontSize: 14, color: 'grey'}}/>
                                                    <Box
                                                        className="info"
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '120%',
                                                            left: '50%',
                                                            transform: 'translateX(-50%)',
                                                            zIndex: 10,
                                                            transition: 'all 0.3s ease',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            width: 0,
                                                            opacity: 0,
                                                            bgcolor: 'background.paper',
                                                            px: 1,
                                                            py: 0.5,
                                                            borderRadius: 1,
                                                            boxShadow: 1,
                                                        }}
                                                    >
                                                        {lead.email}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell onClick={() => handleLeadClick(lead)}>{lead.municipality}</TableCell>
                                    <TableCell onClick={() => handleLeadClick(lead)}>
                  <span style={{
                      height: '12px',
                      backgroundColor: lead.assignedStatus?.colour || "#fff",
                      display: 'inline-block',
                      border: '1px solid #000',
                      margin: '4px',
                      padding: '4px 5px',
                      borderRadius: '3px',
                      fontSize: '10px',
                      lineHeight: '14px'
                  }}>{lead.assignedStatus?.name?.replace('/', ' / ').toLowerCase() || "no status"}</span>
                                    </TableCell>
                                    {user?.role === 'admin' && (<TableCell onClick={() => handleLeadClick(lead)}>
                                        {lead.assignedAgent
                                            ? `${lead.assignedAgent.username} ${
                                                lead.assignedAgent.isRestricted ? '(Restricted)' : ''
                                            }`
                                            : 'Unassigned'}
                                    </TableCell>)}
                                    <TableCell onClick={() => handleLeadClick(lead)}>
                                        {moment(lead.createdAt).format('lll')}
                                    </TableCell>
                                    {user.role === 'agent' && (
                                        <TableCell>
                                            {lead.status === 'Pending' ? (
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        color="success"
                                                        onClick={() => handleStatusChange(lead, 'Accepted')}
                                                        sx={{marginRight: 1}}
                                                    >
                                                        Accept
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => handleStatusChange(lead, 'Rejected')}
                                                    >
                                                        Reject
                                                    </Button>
                                                </>
                                            ) : lead.isAfterFirstMeeting && lead.status === 'Accepted' && lead.status !== 'Completed' ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setSelectedLead(lead);
                                                        handleReviewOpen();
                                                    }}
                                                >
                                                    First Contact Review
                                                </Button>
                                            ) : (
                                                <FcCheckmark/>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                sx={{mt: 2, display: 'flex', justifyContent: 'center'}}
            />

            <Modal open={showMetWithLeadPopup} onClose={handleCloseMetWithLead}>
                <Box className="modal-box" sx={{...modalBoxStyles}}>
                    <Typography variant="h6" component="h2">
                        How did the meeting go?
                    </Typography>
                    <form onSubmit={handleMetWithLeadForm} autoComplete="off">
                        <TextField
                            label="Comments"
                            name="comments"
                            value={formData.comments}
                            onChange={handleMetWithLeadInputChange}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            required
                        />
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status"
                                name="status"
                                value={formData.status}
                                onChange={handleMetWithLeadInputChange}
                                label="Status"
                            >
                                <MenuItem value="Ready to Sell">Ready to Sell</MenuItem>
                                <MenuItem value="Not Ready to Sell">Not Ready to Sell</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </form>
                </Box>
            </Modal>


            <Modal open={open} onClose={handleClose}>
                <Box className="modal-box" sx={{...modalBoxStyles}}>
                    <Typography variant="h6" component="h2">
                        Add New Lead
                    </Typography>
                    <form onSubmit={handleAddLead} autoComplete="off">
                        <TextField
                            label="Name"
                            name="name"
                            value={newLead.name}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Email"
                            name="email"
                            value={newLead.email}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Phone"
                            name="phone"
                            value={newLead.phone}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Municipality"
                            name="municipality"
                            value={newLead.municipality}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        
                        <Button type="submit" variant="contained" color="primary">
                            Add Lead
                        </Button>
                    </form>
                </Box>
            </Modal>

            <Modal open={editOpen} onClose={handleEditClose}>
                <Box className="modal-box" sx={{...modalBoxStyles}}>
                    <Typography variant="h6" component="h2">
                        Edit Lead
                    </Typography>
                    <form onSubmit={handleEditLead}>
                        <TextField
                            label="Name"
                            name="name"
                            value={editLead.name}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Email"
                            name="email"
                            value={editLead.email}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Phone"
                            name="phone"
                            value={editLead.phone}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Municipality"
                            name="municipality"
                            value={editLead.municipality}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <Button type="submit" variant="contained" color="primary">
                            Save Changes
                        </Button>
                    </form>
                </Box>
            </Modal>

            <Modal open={eventOpen} onClose={handleEventClose}>
                <Box className="modal-box" sx={{...modalBoxStyles}}>
                    <Typography variant="h6" component="h2">
                        Add New Event
                    </Typography>
                    <form onSubmit={handleAddEvent}>
                        <TextField
                            label="Event Name"
                            name="eventName"
                            value={newEvent.eventName}
                            onChange={handleEventChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Event Date"
                            name="eventDate"
                            type="datetime-local"
                            value={newEvent.eventDate}
                            onChange={handleEventChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <Box display="flex" alignItems="center" marginTop={2}>
                            <input
                                type="checkbox"
                                name="notify"
                                checked={newEvent.notify}
                                onChange={handleEventChange}
                            />
                            <Typography variant="body1" marginLeft={1}>
                                Notification
                            </Typography>
                        </Box>
                        <Button type="submit" variant="contained" color="primary">
                            Add Event
                        </Button>
                    </form>
                </Box>
            </Modal>

            <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                <DialogTitle>Delete Lead</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this lead?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteLead} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Modal open={reviewOpen} onClose={handleReviewClose}>
                <Box className="review-modal-box" sx={{...modalBoxStyles}}>
                    <Typography variant="h6" component="h2">
                        First Contact Review
                    </Typography>
                    <form onSubmit={handleAddReviewNote}>
                        <TextField
                            label="Note"
                            value={reviewNote}
                            onChange={(e) => setReviewNote(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                            multiline
                            rows={4}
                        />
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                        <Button onClick={handleReviewClose} variant="contained" color="secondary">
                            Close
                        </Button>
                    </form>
                </Box>
            </Modal>

            <React.Fragment>
                {sidebarOpen && (
                    <Box
                        onClick={handleSidebarClose}
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 999,
                        }}
                    />
                )}

                <Box
                    className={`right-sidebar ${sidebarOpen ? 'open' : ''}`}
                    sx={{
                        position: 'fixed',
                        top: 0,
                        right: sidebarOpen ? 0 : '-300px',
                        width: '300px',
                        height: '100%',
                        backgroundColor: '#f4f4f4',
                        boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.5)',
                        transition: 'right 0.3s ease',
                        overflowY: 'auto',
                        zIndex: 1000,
                    }}
                >
                    <div className='leads content'>
                    {selectedLead ? (
    <>
        <Typography variant="h6">
            <MuiLink component={RouterLink} onClick={() => {
                handleSidebarClose();
                navigate(`/leads/${selectedLead._id}`);
            }} to={`/leads/${selectedLead._id}`} underline="hover">
                {selectedLead.name}
            </MuiLink>
        </Typography>
        <Typography>Email: {selectedLead.email}</Typography>
        <Typography>Phone: {selectedLead.phone}</Typography>
        <Typography>Municipality: {selectedLead.municipality}</Typography>

        <Box marginTop={2}>
            {user.role === 'admin' && (
                <>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="agentSelect-label" shrink>Assign Agent:</InputLabel>
                        <Select
                            labelId="agentSelect-label"
                            id="agentSelect"
                            value={selectedAgent}
                            onChange={(e) => setSelectedAgent(e.target.value)}
                        >
                            <MenuItem value="">Select Agent</MenuItem>
                            {agents.map((agent) => (
                                <MenuItem
                                    key={agent._id}
                                    value={agent._id}
                                    disabled={agent.isLocked || agent.isRestricted || agent.isOnHoliday}
                                >
                                    {agent.username}
                                    {agent.isLocked ? ' (Locked)'
                                        : agent.isRestricted ? ' (Restricted)'
                                            : agent.isOnHoliday ? ' (Unavailable)' : ''}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        onClick={handleAssignAgent}
                        variant="contained"
                        color="primary"
                        sx={{marginBottom: 2}}
                    >
                        Assign
                    </Button>
                </>
            )}
        </Box>
                                <Box marginTop={2}>
                                    <div>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel id="assignedStatusSelect-label" shrink>Assign
                                                status:</InputLabel>
                                            <Select
                                                labelId="assignedStatusSelect-label"
                                                id="assignedStatusSelect"
                                                value={assignedStatus || (selectedLead.assignedStatus && selectedLead.assignedStatus._id) || ""}
                                                onChange={handleAssignedStatusChange}>
                                                <MenuItem key="" value="">no status assigned</MenuItem>
                                                {leadsStatuses.map((status) => (
                                                    <MenuItem
                                                        disabled={selectedLead.assignedStatus && selectedLead.assignedStatus._id !== "67444c940c041b10602cdc1a"
                                                            && status._id === "67444c940c041b10602cdc27"}
                                                        key={status._id}
                                                        value={status._id}
                                                        onClick={() => {
                                                            definedActions[status.definedAction]();
                                                        }}>
                              <span style={{
                                  width: '8px',
                                  height: '8px',
                                  backgroundColor: status.colour,
                                  display: 'inline-block',
                                  border: '1px solid #000',
                                  margin: '0 10px 0 0'
                              }}/>{status.name.toLowerCase().replace('/', ' / ')}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {showMeetingDateField && (
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    id="statusDate"
                                                    label="Date of Planned Meeting"
                                                    type="datetime-local"
                                                    InputLabelProps={{shrink: true}}
                                                    onChange={handleDateChange}
                                                />
                                            )}
                                            <Button
                                                onClick={handleAssignStatus}
                                                variant="contained"
                                                color="primary"
                                                sx={{marginTop: 1, marginBottom: 2}}
                                            >
                                                set status
                                            </Button>
                                        </FormControl>
                                    </div>
                                </Box>
                                <Typography variant="h6">Notes:</Typography>
        {isLoadingNotes ? (
            <Typography>Loading notes...</Typography>
        ) : (
            <Box component="ul" sx={{padding: 0, listStyleType: 'none'}}>
                {notes
                    ?.filter((log) => log.type === 'note' || log.type === 'review note')
                    ?.map((note) => (
                        <Box
                            key={note._id}
                            component="li"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '5px',
                                marginBottom: '5px',
                                backgroundColor: '#ffffcc',
                                borderRadius: '5px',
                            }}
                        >
                            <NoteIcon sx={{marginRight: 1}}/>
                            <Box>
                                <Typography variant="body2">
                                    {note.message}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                    {new Date(note.createdAt).toLocaleString('en-US', {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
            </Box>
        )}

        {user.role === 'admin' && (
            <>
                <Typography variant="h6">Logs:</Typography>
                <Box component="ul" sx={{padding: 0, listStyleType: 'none'}}>
                    {logs
                        .filter(log => log.type !== 'note' && log.type !== 'review note')
                        .map((log) => (
                            <Box
                                key={log._id}
                                component="li"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '5px',
                                    marginBottom: '5px',
                                    backgroundColor: log.type === 'event' ? '#cce5ff' : '#f0f0f0',
                                    borderRadius: '5px',
                                }}
                            >
                                {log.type === 'event' ? (
                                    <EventIcon sx={{marginRight: 1}}/>
                                ) : (
                                    <EditIcon sx={{marginRight: 1}}/>
                                )}
                                <Typography variant="body2">
                                    {log.message} -{' '}
                                    {new Date(log.createdAt).toLocaleString('en-US', {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </Typography>
                            </Box>
                        ))}
                </Box>
            </>
        )}

        <Box marginTop={2}>
            <TextField
                label="Add Note"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button onClick={handleAddNote} variant="contained" color="primary">
                Add Note
            </Button>
        </Box>

        <Box marginTop={2}>
            <Button
                onClick={handleEditOpen}
                variant="contained"
                startIcon={<EditIcon/>}
                sx={{marginRight: 1}}
            >
                Edit
            </Button>
            {user.role === 'admin' && (
                <Button
                    onClick={handleDeleteOpen}
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon/>}
                >
                    Delete
                </Button>
            )}
        </Box>
    </>
) : (
    <Typography>Select a lead to view details</Typography>
)}
                    </div>
                </Box>
            </React.Fragment>
        </Box>
    );
};

const modalBoxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 4,
    borderRadius: '8px',
};

export default Leads;
